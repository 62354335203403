@import 'src/styles/mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 22px 37px 0 21px;

  position: fixed;
  width: 100%;
  z-index: 2;

  @include screen('tablet') {
    align-items: center;
    background-color: #191c1f;
    padding: 16px 24px 16px 21px;
  }

  @include screen('mobile') {
    align-items: center;
    background-color: #191c1f;
    padding: 8px 16px 7px 11px;
  }

  .n12-logo {
    transition: opacity 0.3s;
    width: 60px;

    svg {
      width: 100%;
      height: inherit;
    }

    @include screen('tablet') {
      width: 54px;
      height: 24px;
    }

    @include screen('mobile') {
      width: 40px;
      height: 18px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .centered-logo {
    margin-right: 25px;
    margin-top: -3px;
    width: 83px;
    svg {
      display: block;
      width: 100% !important;
    }
    @include screen('mobile') {
      width: auto;
    }
  }
}
