@import 'src/styles/theme.scss';

.default-next,
.default-prev {
  position: absolute;
  top:0;
  width: 36px!important;
  height: 100%!important;
  display: flex!important;
  align-items: center;
  justify-content: center;
  background: #31363F!important;
  transition: 0.3s;
  z-index: 1;
  cursor: pointer;
  
  &:hover {
    background: #3c3f41!important;
  }

  &.disabled {
    pointer-events: none;
    background-color: #242424;

    svg {
      path {
        fill: #767676;
      }
    }
  }

  .icon-prev {
    transform: rotate(180deg);
  }
}

.default-next {
  left: 0;
}

.default-prev {
  right: 0;
}

.external-next,
.external-prev {
  width: 36px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F31229;
  border: 2px solid #F31229;
  cursor: pointer;
  transition: 0.3s;
  
  &:hover {
    border-color: #EEF1F7;
  }

  &.active {
    border-color: #EEF1F7;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .icon-prev {
    transform: rotate(180deg);
  }
}