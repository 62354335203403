@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/earlyaccess/opensanshebrew.css");
@font-face {
  font-family: "Yonit-Light";
  src: url("./fonts/Yonit-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Regular";
  src: url("./fonts/Yonit-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Medium";
  src: url("./fonts/Yonit-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Bold";
  src: url("./fonts/Yonit-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Digital Display";
  src: url("./fonts/DS-DIGIB.TTF");
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 62.5%;
}

html,
body {
  direction: rtl;
  padding: 0;
  margin: 0;
  font-family: "Yonit-Regular", sans-serif;
  overflow-x: hidden;
}
html.full-height,
body.full-height {
  height: 100%;
  overflow-y: hidden;
}

a {
  text-decoration: none;
  color: unset;
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
}

.slick-slide {
  float: left !important;
  direction: rtl;
}
.slick-slide[data-index="0"] {
  position: relative;
}
.slick-slide[data-index="0"]:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 28px;
  height: 1px;
  background-color: #191d20;
}
.slick-slide:first-child {
  position: relative;
}
.slick-slide:first-child:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 42px;
  width: 100%;
  height: 1px;
  background-color: #191d20;
}

.dimensions-66cc7e80457ec388ff32dfcd.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: calc(100vh - 96px - 179px - 102px - 32px - 6vh);
}

.video-js .vjs-tech {
  background-color: #191d20;
}