@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.video-bg {
  height: 100vh;
  z-index: -1;
  position: fixed;
  left: 0;
  width: 100%;
  object-fit: cover;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  color: #fff;
  max-width: 515px;
  min-height: 100vh;
  height: calc(100% - 60px);
  margin: 0 auto;
  text-align: center;

  @include screen('mobile') {
    // justify-content: flex-end;
    padding: 0 27px 62px;
  }

  .main-logo-with-text {
    max-width: 214px;
    max-height: 172px;

    @include screen('mobile') {
      max-width: 195px;
      max-height: 157px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    margin: 37px 0 0;
    font-family: $bold-font;
    font-size: 21px;
    line-height: 26px;
    
    @include screen('mobile') {
      margin: 36px auto 0;
      max-width: 338px;
      font-size: 19px;
      line-height: 24px;
    }
  }

  .text {
    padding: 10px 0 56px;
    max-width: 430px;
    font-size: 18px;
    line-height: 24px;

    @include screen('mobile') {
      padding: 47px 0 65px;
      max-width: 300px;
      margin: 0 auto;
      padding: 8px 0 48px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .link {
    position: relative;
    width: 175px;
    height: 42px;
    margin-bottom: 60px;
    border: 2px solid #F31229;
    font-family: $bold-font;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -0.41px;
    transition: 0.25s;

    &:hover {
      border-color: white;
    }

    .progress-bg {
      position: absolute;
      top: 0;
      right: 0;
      // width: 100%;
      height: 100%;
      background-color: #F31229;
      z-index: -1;

      &.active {
        animation: 10s linear progress;
      }
    }
  }
}

.rabbi-credits {
  position: fixed;
  bottom: 20px;
  left: 30px;
  font-size: 12px;
  color: #979ca5; //#676b74;

  @media only screen and (max-height: 500px) and (min-width: 769px){
    position: relative;
    left: 0;
  }

  a {
    color:#979ca5;
    transition: 0.3s;
    &:hover {
      color:#EEF1F7;
      text-decoration: underline;
    }
  }
}

@keyframes progress {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}