.shares {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  column-gap: 5px;
}
.share-btn {
  width: 35px;
  height: 39px;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out, border 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
