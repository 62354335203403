@import './theme.scss';

html {
  font-size: 62.5%;
}

html,
body {
  direction: rtl;
  padding: 0;
  margin: 0;
  font-family: $regular-font;
  // background-color: #191d20;
  overflow-x: hidden;
  &.full-height {
    height: 100%;
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
  color: unset;
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
}

.slick-slide {
  float: left!important;
  direction: rtl;

  &[data-index="0"]{
    position: relative;
  
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right:0;
      width: 28px;
      height: 1px;
      background-color: #191d20;
    }
  }

  &:first-child {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 42px;
      width: 100%;
      height: 1px;
      background-color: #191d20;
    }
  }
}

.dimensions-66cc7e80457ec388ff32dfcd.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: calc(100vh - 96px - 179px - 102px - 32px - (4.9vh + 1.1vh));
}

.video-js .vjs-tech {
  background-color: #191d20;
}

// .slick-prev:before,
// .slick-next:before {
//   content: ''!important;
// }

// .slick-prev {
//   right: -36px!important;
// }
// .slick-next {
//   left: -36px!important;
// }

// .slick-prev.slick-disabled,
// .slick-next.slick-disabled {
//   background-color: #242424!important;
//   pointer-events: none;

//   svg {
//     path {
//       fill: #767676;
//     }
//   }
// }

// .slick-slider .slick-track, .slick-slider .slick-list {
//   direction: ltr;
// }

// .timeline-slider {
//   touch-action: pan-y;
// }

// .timeline-slider .slick-track {
//   display: flex;
//   overflow-y: auto;  /* Enable horizontal scrolling */
//   scroll-behavior: smooth;
// }

// .timeline-slider .slick-slide {
//   flex: 0 0 auto;
// }

// /* Optional: Hide scrollbar in Webkit-based browsers */
// .timeline-slider .slick-track::-webkit-scrollbar {
//   display: none;
// }