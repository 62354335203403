@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.content {
  padding-top: 63px;
  margin: 0 auto;

  @include screen('mobile') {
    padding-top: 42px;
  }

  .scrollable-section {
    padding: 0 10px 70px;
    text-align: center;
    max-width: 552px;
    margin: 0 auto;

    @include screen('mobile') {
      padding-bottom: 102px;
    }

    h1 {
      display: inline;
      padding: 3px 6px;
      font-size: 28px;
      line-height: 1.358;
      background-color: #f31229;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }

    .description {
      font-family: $open-sans;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.41px;
      padding-top: 35px;

      @include screen('mobile') {
        padding-bottom: 22px;
        text-align: right;
        padding: 35px 15px 0;
      }

      p {
        padding-bottom: 20px;
      }
    }
  }

  .fixed-section {
    width: 100%;

    @include screen('mobile') {
      position: fixed;
    }
  }

  .player-section {
    display: flex;
    background-color: #323741;

    // @include screen('tablet') {
    //   background-color: #222535;
    // }

    &.is-minified {
      .timer {
        width: 48%;
        opacity: 1;
      }

      .player {
        width: 52%;
      }
    }

    .timer {
      width: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 57px;
      opacity: 0;
      transition: 0.5s;
    }
  }

  .player {
    width: 100%;
    transition: 0.5s;

    @include screen('tablet') {
      $h: calc(100vh - 62px - 123px);
      width: calc((#{$h} / 9) * 16);
      margin: 0 auto;
    }
  }

  .timer {
    font-family: $digital-font;
    font-weight: 400;
    font-size: 98.36px;
    line-height: 1;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 24px;

    @include screen('mobile') {
      font-size: 88.36px;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 10px;
    }
  }

  .articles {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 47px;

    @include screen('mobile') {
      padding-top: 0;
      padding-bottom: 28px;
    }

    .article-item {
      color: white;
      display: flex;
      gap: 20px;
      background-color: #272a31;
      margin-bottom: 19px;

      @include screen('mobile') {
        gap: 15px;
        margin-bottom: 14px;
      }

      .poster {
        width: 29%;
        max-width: 160px;
        height: 86px;
        object-fit: cover;
        background-color: #a09f9e;

        @include screen('mobile') {
          max-width: 108px;
        }
      }

      .title {
        width: calc(100% - 29% - 20px);
        font-family: $medium-font;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.41px;
        text-decoration: underline;
        text-align: right;
        color: #eef1f7;
        padding: 18px 0 5px 5px;
      }
    }
  }
}

.timeline {
  position: relative;
  width: 100%;
  height: 123px;
  background: rgb(51, 55, 66);
  background: linear-gradient(180deg, rgba(51, 55, 66, 0.9976584383753502) 0%, rgba(25, 29, 32, 1) 100%);
  // margin: 0 40px;
  // overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20px;
    background: rgb(51, 55, 66);
    background: linear-gradient(180deg, rgb(28, 32, 36) 0%, rgb(25, 29, 32) 100%);
  }

  .timeline-scrollable {
    display: flex;
    gap: 58px;
    height: inherit;
    padding-top: 24px;
    overflow-y: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      height: 0.8px;
      background-color: #eef1f7;
      z-index: 0;
    }
  }

  .timeline-item {
    min-width: 93px;
    cursor: pointer;

    &:nth-child(1) {
      position: relative;
      margin-right: 57px;

      @include screen('mobile') {
        margin-right: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 35px;
        width: 57px;
        left: 100%;
        height: 5px;
        background-color: #262a31;
        z-index: 1;

        @include screen('mobile') {
          width: 20px;
        }
      }
    }

    &:last-child {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 35px;
        width: calc(100%);
        right: 14px;
        height: 5px;
        background-color: #262a31;
        z-index: 1;
      }
    }

    &-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
    }

    &.active {
      .time,
      .square {
        color: #eef1f7;
        background-color: #f31229;
      }
    }

    .time {
      width: fit-content;
      padding: 0 5px 0 6px;
      font-family: $digital-font;
      font-size: 19px;
      line-height: 22px;
      letter-spacing: 0.59px;
      color: #222535;
      background-color: #eef1f7;
      transition: 0.3s;
    }

    .square {
      position: absolute;
      top: 31px;
      right: 0;
      width: 14px;
      height: 14px;
      background-color: #eef1f7;
      border: 2px solid #eef1f7;
      transition: 0.3s;
      z-index: 1;
    }

    .name {
      font-size: 14px;
      line-height: 13px;
      color: #eef1f7;
      padding-top: 31px;
    }
  }
}

.external-arrow-controls {
  display: flex;
  gap: 52px;
  justify-content: center;
}

.footer {
  padding: 7px;
  background-color: #272a31;
  border-top: 1px solid #f31229;

  &-shares {
    column-gap: 37px;

    svg {
      path {
        fill: #dadee7;
      }
    }
  }

  .rabbi-credits {
    font-size: 12px;
    color: #979ca5;
    text-align: center;
    padding: 20px 10px 10px;

    a {
      color: #979ca5;
      transition: 0.3s;
      text-decoration: underline;
    }
  }
}

.enter {
  opacity: 0;

  &-active {
    opacity: 1;
    transition: all 300ms ease;
  }
}

.exit {
  opacity: 1;
  &-active {
    opacity: 0;
    transition: all 300ms ease;
  }
}
