@import 'src/styles/theme.scss';

$topPadding: 140px;
$smallHPadding: 100px;

.gradient {
  width: 100%;
  height: $topPadding; // 213px;
  position: fixed;
  top: 0;
  background: rgb(51, 55, 66);
  background: linear-gradient(180deg, rgba(51, 55, 66, 0.9976584383753502) 0%, rgba(25, 29, 32, 1) 100%);
  z-index: 0;

  @media only screen and (max-height: 850px) and (min-width: 1279px) {
    height: $smallHPadding;
  }
}

.content {
  width: 84.4%;
  // height: calc(100vh - 81px);
  padding: $topPadding 0 0;
  margin: 0 auto;
  // display: flex;
  // column-gap: 102px;

  @media only screen and (max-height: 850px) and (min-width: 1279px) {
    padding: $smallHPadding 0 0;
  }

  .scrollable-section {
    // width: 553px;
    // overflow: hidden;

    width: 33.6%;
    margin-right: 65.4%;
    padding-bottom: 102px;

    @media only screen and (max-height: 850px) and (min-width: 1279px) {
      padding-bottom: 60px;
    }

    .scrollable-content {
      height: 100%;
      // overflow-y: auto;

      // &::-webkit-scrollbar {
      //   width: 6px;
      // }

      // &::-webkit-scrollbar-track {
      //   border-radius: 8px;
      //   background-color: #272727;
      //   border: 1px solid #272727;
      // }
      // &::-webkit-scrollbar-thumb {
      //   border-radius: 8px;
      //   background-color: #878787;
      // }

      h1 {
        display: inline;
        font-size: 31px;
        line-height: 1.358;
        padding: 3px 10px;
        background-color: #f31229;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }

      .description {
        font-family: $open-sans;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.41px;
        padding-top: 35px;
        p {
          padding-bottom: 20px;
        }
      }
    }
  }

  .fixed-section {
    // width: 964px;
    width: 50.2%;
    height: calc(100vh - $topPadding - 102px);
    position: fixed;
    // padding-bottom: 102px;
    // display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-height: 850px) and (min-width: 1279px) {
      height: calc(100vh - $smallHPadding - 60px);
    }
  }

  .player {
    $h: calc(100vh - 96px - $topPadding - 102px - 32px - (4.9vh + 1.1vh));
    height: $h;
    // aspect-ratio: 16/9;
    width: calc((#{$h} / 9) * 16);
    max-width: 50vw;

    @media only screen and (max-height: 850px) and (min-width: 1279px) {
      $h: calc(100vh - 96px - $smallHPadding - 60px - 32px - (4.9vh + 1.1vh));
      height: $h;
      // aspect-ratio: 16/9;
      width: calc((#{$h} / 9) * 16);
    }
  }

  .timer {
    font-family: $digital-font;
    font-weight: 400;
    font-size: 98.36px;
    line-height: 1;
    color: #fff;
    padding-bottom: 24px;
  }

  .slider-timeline {
    position: relative;
    width: 100%;
    height: 96px;
    padding: 0 36px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      height: 0.8px;
      background-color: #eef1f7;
      z-index: -1;
    }

    &.action-button-hidden {
      $actionBtnH: calc(32px + 6vh);
      margin-bottom: $actionBtnH;
    }

    .timeline-item {
      width: 93px;
      cursor: pointer;

      &:hover {
        .time,
        .square {
          color: #eef1f7;
          background-color: #2c2f37;
        }
      }

      &-inner {
        position: relative;
        padding: 8px 28px 0 10px;
        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // justify-content: space-between;
        text-align: right;
      }

      &.active {
        .time,
        .square {
          color: #eef1f7;
          background-color: #f31229;
        }
      }

      .time {
        width: fit-content;
        padding: 0 5px 0 6px;
        font-family: $digital-font;
        font-size: 19px;
        line-height: 22px;
        letter-spacing: 0.59px;
        color: #222535;
        background-color: #eef1f7;
        transition: 0.3s;
      }

      .square {
        position: absolute;
        top: 41px;
        right: 28px;
        width: 14px;
        height: 14px;
        background-color: #eef1f7;
        border: 2px solid #eef1f7;
        transition: 0.3s;
      }

      .name {
        padding-top: 33px;
        font-size: 14px;
        line-height: 13px;
        color: #eef1f7;

        @media only screen and (max-width: 1578px){
          padding-top: 31px;
          font-size: 13px;
          line-height: 12px;
        }

        @media only screen and (max-width: 1345px){
          font-size: 12px;
        }
      }
    }
  }

  .articles {
    width: 100%;
    padding-top: 44px;
    padding-bottom: 29px;

    .article-item {
      color: white;
      display: flex;
      gap: 20px;
      background-color: #272a31;
      margin-bottom: 19px;

      .poster {
        width: 29%;
        max-width: 160px;
        height: 86px;
        object-fit: cover;
        background-color: #a09f9e;

        // 1280     width: 37.5%;
      }

      .title {
        width: calc(100% - 29% - 20px);
        font-family: $medium-font;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.41px;
        text-decoration: underline;
        color: #eef1f7;
        padding: 17px 0 0 4px;
      }
    }
  }
}

.external-arrow-controls {
  display: flex;
  gap: 23px;
  justify-content: flex-end;
}

.rabbi-credits {
  position: fixed;
  bottom: 20px;
  font-size: 12px;
  color: #979ca5; //#676b74;

  a {
    color: #979ca5;
    transition: 0.3s;
    &:hover {
      color: #eef1f7;
      text-decoration: underline;
    }
  }
}

.enter {
  opacity: 0;

  &-active {
    opacity: 1;
    transition: all 300ms ease;
  }
}

.exit {
  opacity: 1;
  &-active {
    opacity: 0;
    transition: all 300ms ease;
  }
}
