@import 'src/styles/mixins.scss';

.action-button {
  font-size: 18px;
  line-height: 30px;
  border: 1.5px solid #f31229;
  cursor: pointer;
  width: 152px;
  height: 32px;
  text-align: center;
  margin-top: 4.9vh;
  margin-bottom: 1.1vh;
  transition: 0.3s;

  @include screen('tablet'){
    width: 147px;
    height: 30px;
    margin: 0;
  }

  @include screen('mobile'){
    width: 113px;
    height: 24px;
    margin: 0;
    font-size: 14px;
    line-height: 23px;
  }

  &:hover {
    background-color: #f31229;

    @include screen('mobile'){
      background-color: transparent;
    }
  }
}

.disabled {
  border-color: #191D1F;
  background-color: #323640;
  color: rgba(255,255,255, 0.25);
  pointer-events: none;
}